* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  .navbar2 {
    width: 100%;
    height: 80px;
    background-color: black;
  }
  .navbar2-container {
    display: flex;
    align-items: center;
    padding: 0px 50px;
    height: 100%;
    color: #fff;
  }
  .logo2-container {
    flex: 1;
    display: grid;
    align-items: center;
  }
  .logo2 {
    width: auto;
    height: 60px;
    cursor: pointer;
  }
  .menu2-container {
    flex: 3;
    display: grid;
    align-items: center;
  }
  .menu2-list {
    display: flex;
    list-style: none;
    cursor: pointer;
  }
  .menu2-list-item {
    margin: 30px;
    transition: all 0.3s ease-in-out;
  }
  .menu2-list-item:hover {
    transform: scale(0.8);
  }
  .menu2-list-item.active {
    font-weight: bold;
  }
  .profile2-container {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .profile2-picture {
    width: 10%;
    height: 10%;
    cursor: pointer;
    object-fit: cover;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  .profile2-picture:hover {
    transform: scale(0.8);
  }
  .profile2-text-container {
    margin: 0 15px;
  }
  .toggle2 {
    width: 40px;
    height: 20px;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
  .toggle2-icon {
    color: goldenrod;
    font-size: 13px;
  }
  .toggle2-ball {
    width: 18px;
    height: 18px;
    background-color: black;
    position: absolute;
    right: 1px;
    border-radius: 50%;
    cursor: pointer;
  }
  .sidebar2 {
    width: 50px;
    height: 100%;
    background-color: black;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;
  }
  .left-menu-icon {
    color: #fff;
    font-size: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .left-menu-icon:hover {
    transform: scale(0.8);
  }
  .container2 {
    min-height: calc(100vh - 50px);
    color: #fff;
  }
  .content2-container {
    margin-left: 50px;
  }



  .desenfoqueopaco {
    height: 92vh;

    background: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }


  .featured2-content:nth-last-child(2) {
    height: 80vh;
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }


.bienvenida{
 position: absolute; 
 top: 35%;
 text-align: center;
align-content: center;}

.imagendefondo{
position: relative;
  width: 100%;
height: 100%;
object-fit: cover;
opacity: 50%;
height: 60vh;
z-index: -1000;
}


  .featuredprofile-content {
    height: 50vh;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    margin-bottom: 0px;
  }


  .featured2-title {
    width: 500px;
    text-align: center;
    line-height: 1;
    color: #fff;
    font-size: 45px;
    font-family: "Poppins", sans-serif;
  }
  .featured2-desc {
    text-align: center;
    color: #fff;
    font-size: 25px;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
  }
  .featured2-button {
    background-color: #f40612;
    color: #fff;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-top: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .featured2-button:hover {
    transform: scale(0.8);
  }
  .btn-icon2 {
    margin-left: 5px;
  }
  .movie2-list-container {
    padding: 0 20px;
  }
  .movie2-list-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: -20px;
  }
  .movie2-list-title {
    margin-top: 40px;
    font-size: 25px;
    font-family: "Poppins", sans-serif;
  }
  .movie2-list {
    display: flex;
    align-items: center;
    height: 300px;
  }
  .movie2-list-item {
    margin-right: 15px;
    position: relative;
  }
  .movie2-list-item-img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .movie2-list-item:hover .movie2-list-item-img {
    transform: scale(0.9);
    opacity: 0.3;
    backdrop-filter: blur(10px);

  }
  .movie2-list-item:hover .movie2-list-item-title,
  .movie2-list-item:hover .movie2-list-item-desc,
  .movie2-list-item:hover .movie2-list-item-button {
    opacity: 1;
  }
  .movie2-list-item-title {
    padding: 0 12px;
    font-size: auto;
    font-weight: bold;
    position: absolute;
    top: 15%;
    left: 10%;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .movie2-list-item-desc {
    padding: 12px;
    font-size: auto;
    position: absolute;
    top: 32%;
    left: 10%;
    width: 250px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .movie2-list-item-button {
    padding: 8px 10px;
    background-color: #c70913;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    bottom: 10%;
    right: 15%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  /* Toggle Dark Mode  */
  .container2.active {
    background-color: white;
  }
  .movie2-list-title.active {
    color: #151515;
  }
  .navbar2-container.active {
    background-color: #fff;
    color: #151515;
  }
  .toggle2.active {
    background-color: #151515;
  }
  .toggle2-ball.active {
    background-color: #fff;
    transform: translateX(-20px);
  }
  .sidebar2.active {
    background-color: #fff;
  }
  .left2-menu-icon.active {
    color: #151515;
  }

  .modal2 {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    background-color: #c70913;
  }
  


  .cerravideo  {


    position: fixed; /* Stay in place */
    right: 4%;
    bottom:7%;

    z-index: 1000; /* Sit on top */
  }



  .videoyoutube{


    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 80%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */

  
  
}


.detallesvideo{


  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 20%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */



}


.botonsiguiente{
  position: absolute; /* Stay in place */
  z-index: -1; /* Sit on top */
  left:15%;
  top: 100px;
  font-size: 5vh;
color: #e4e4e4cc;
background-color:transparent;
border: none;
}


.botonanterior{
  position: absolute; /* Stay in place */
  z-index: 1; /* Sit on top */
  left:3%;
  top: 100px;
font-size: 5vh;
color: #e4e4e4cc;
background-color:transparent;
border: none;
}




  /* Responsive Mobile Friendly  */
  @media screen and (max-width: 600px) {
    .sidebar2 {
      display: none;
    }
    .sidebar2.active {
      display: none;
    }
    .menu2-list {
      display: none;
    }
    .navbar2-container {
      padding: 0 15px;
    }
    .profile2-picture {
      width: 30%;
      height: auto;

    }
    .profile2-text {
      display: none;
    }
    .fa2-solid.fa-caret-down {
      display: none;
    }
    .logo2 {
      width: 80px;
      height: auto;
    }
    .container2 {
      min-height: calc(450vh - 50px);
    }
    .content2-container {
      margin-left: 0px;
    }
    .featured2-content {
      height: 40vh;
    }


    .desenfoqueopaco {
      height: 40vh;
      line-height: 1.2;
      font-size: 25px;
      text-align: center;

    }
  





    .featured2-content:nth-last-child(2) {
      height: 50vh;
    }
    .featured2-title {
      width: 300px;
      line-height: 1.2;
      font-size: 25px;
      text-align: center;
    }
    .featured2-desc {
      font-size: 20px;
    }
    .featured2-button {
      font-size: 15px;
      padding: 12px 20px;
    }
    .movie2-list-container {
      padding: 0 15px;
    }
    .movie2-list-title {
      font-size: 22px;
      margin-bottom: 0px;
    }
    .movie2-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
    }
    .movie2-list-item {
      margin-right: 0px;
    }
    .movie2-list-item-img {
      
      margin: 10px;
      width: 300px;
      height: 300px;
      border-radius: 15px;
    }



  }


  .redes-flotantes {
    position: fixed;
    right: 4%;
    bottom: 4%;
    z-index: 20;
    }
    .redes-flotantes img {
    float: right; clear: right;
     margin: 5px;
    -moz-transform: scale(.8) ;
    -webkit-transform: scale(.8) ;
    -o-transform: scale(.8) ;
    -ms-transform: scale(.8) ;
    transform: scale(.8) ;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    }
    .redes-flotantes img:hover {
    -moz-transform: scale(1.1) rotate(6deg);
    -webkit-transform: scale(1.1) rotate(6deg);
    -o-transform: scale(1.1) rotate(6deg);
    -ms-transform: scale(1.1) rotate(6deg);
    transform: scale(1.1) rotate(6deg);
    }

    
.imagencurso{

  width: 100%;
padding-left: 20px; 
max-width: 600px;
max-height: 600px;
  }
  